.extra-service-card {
    box-shadow: 0 10px 20px 0 rgba(61, 82, 102, 0.2);
    height: 100%;
    border-radius: 2px;

    & .ant-card-body {
        height: 100%;
        padding-top: 40px;

        .ant-row {
            height: 100%
        }
    }

    p {
        text-align: center;
    }

    .status-tag {
        position: absolute;
        top: 8px;
        right: 0px;
    }

    .price-blue-text {
        text-align: center;
        font-size: 16px;
        line-height: 26px;
        color: #079DC1;
        width: 100%;
        display: inline-block;
    }

    .bottom-wrap {
        margin-top: 6px;
    }
}

.accept-button {
    width: 100%;
    background-color: #079DC1;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.01);
    border-radius: 2px;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 20px;
    color: #FFFFFF;
    height: unset;
    margin-top: 10px;

    &.disabled {
        background-color: #f2f2f2;
        color: #a2a2a2;
    }
}
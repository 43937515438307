@media screen and (max-width: 1200px) {
    .deposit-info-col {
        order: 1;
        width: 100%;
        padding: 24px 24px 0;
    }

    .payment-col {
        order: 2;
    }
}

@media screen and (max-width: 927px) {

    .add-value-container {
        display: block;
    }

    .add-value-container .ant-btn {
        margin-bottom: 10px;
    }

    .money-input {
        width: 250px;
    }
}

@media screen and (max-width: 991px) {

    .main-content,
    .header-responsive {
        margin-left: 0;
    }

    .ant-layout-sider {
        position: absolute;
        height: 100vh;
        z-index: 100;
    }

    .ant-layout-sider-zero-width-trigger {
        position: absolute;
        top: 0px;
    }

    .ant-layout-header {
        padding-left: 45px !important;
    }

    .notification-buttons button {
        margin-bottom: 10px;
        width: 100%;
    }

}
@use './CSS/exam.scss';
@use './CSS/extra-service-card.scss';
@use './CSS/instructor-card.scss';
@use './CSS/custom-player.scss';
@use './CSS/question.scss';
@use './CSS/checkpoints.scss';
@use './CSS/theory-exam.scss';
@import '~antd/dist/antd.css';
@import './CSS/e-learning.scss';

.paragraph {
    color: #676B73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.paragraph-xs {
    color: #818898;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.paragraph-xxs {
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
}

.bold-paragraph {
    color: #252529;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.heading-xl {
    color: #252529;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
}

.heading2 {
    color: #252529;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.heading3 {
    color: #252529;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#detach-button-host {
    display: none !important;
}

.App .logo {
    padding: 16px;
    padding-left: 24px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;

    .flags {
        margin-left: 11px;
        white-space: nowrap;

        button {
            padding: 0;
            background-color: unset;
            border: unset;

            &:hover {
                cursor: pointer;
            }

            img {
                margin: 0 2px;
                width: 20px;
                height: 15px;
                border-radius: 2px;

                &.active {
                    -webkit-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                    -moz-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                    box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);

                }
            }
        }


    }

}

.login-wrapper {
    height: 100%;

    .flags {
        margin-left: 11px;
        white-space: nowrap;
        position: relative;
        top: 30px;
        left: 63px;

        @media screen and (max-width: 768px) {
            top: 90px;
            left: 25px;
        }

        button {
            padding: 0;
            background-color: unset;
            border: unset;
            width: 20px;
            height: 20px;
            margin-left: 5px;

            &:hover {
                cursor: pointer;
            }

            img {
                // margin: 0 2px;
                width: 20px;
                height: 15px;
                border-radius: 2px;

                &.active {
                    -webkit-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                    -moz-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                    box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);

                }
            }
        }
    }

    img {
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.4;
        background-color: #000000;
    }

    .login-form {
        max-width: 375px;
        width: 100%;
    }

    .slot-oportunity-form {
        max-width: 375px;
        width: 100%;
    }

    .login-layout {
        // background: url('/img/image-login.jpg');
        // background-size: 100% 100%, cover;
        height: 100%;
        max-width: 1600px;
        margin-right: auto;
        margin-left: auto;

        .logo {
            width: 159px;
            height: 50px;
            padding: 0;

            @media screen and (max-width: 768px) {
                margin-top: 45px;
            }
        }

        .login-screen-row {
            height: 100%;

            .centering-col {
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    align-items: center;
                    justify-content: start;
                    padding: 0 8px;
                }
            }
        }
    }

}

.App {
    height: 100vh;
}

.ant-layout-content {
    padding-bottom: 60px;
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottom {
    padding: 0 30px 30px;
    text-align: center;
}

.bottom .student-name {
    display: block;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.bottom .btn {
    background: #FFFFFF;
    color: #000000;
    border-radius: 4px;
    padding: 5px 20px;
    display: block;

    &.ghost {
        background-color: unset;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
    }
}


.main-content,
.header-responsive {
    margin-left: 270px;
    padding: 20px;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        padding: 8px;
    }

    .header-row {
        padding: 16px;
        margin: -20px -20px 16px;

        @media screen and (max-width: 768px) {
            padding: 16px 50px;
        }
    }
}

.select-instructor-header-row {
    margin: -20px -20px 0;

    &>div {
        width: 100%;
        padding: 16px 16px 16px 50px;
    }

    @media screen and (max-width: 520px) {

        .title-hide-mob {
            display: none;
        }
    }

    .select-instructor-filter-row {



        @media screen and (max-width: 1100px) {

            b {
                width: 100%;
                margin-bottom: 10px;
            }

            .filter-select {
                margin-bottom: 10px;
            }

            .reset-btn {
                margin: 0 10px;
            }
        }

        @media screen and (max-width: 520px) {

            b {
                width: 100%;
                margin-bottom: 10px;
            }

            .filter-select {
                width: 100% !important;

            }

            .reset-btn {
                margin: 0 10px;
            }
        }

        @media screen and (max-width: 520px) {

            .filter-select {
                width: 100% !important;

            }

            .title-hide-mob {
                display: none;
            }
        }
    }
}

.skeleton-main-layout {
    margin-left: 270px;

    @media screen and (max-width: 991px) {
        margin-left: 15px;
    }
}

.responsive-card {

    .ant-card-body,
    .ant-card-head {

        @media screen and (max-width: 768px) {
            padding: 15px;
        }
    }
}

.ant-table-thead>tr>th {
    border-right: 1px solid #f0f0f0;
}

.ant-table-content table td {
    white-space: nowrap;
}

.table-page {
    overflow: scroll;
}

.ant-table {
    overflow: scroll;
}

.clickable-row {
    cursor: pointer;
}


.student-payments-row-plus {
    background-color: #d9f7be;
}

.student-payments-row-promo {
    background-color: #f7f3be;
}

.student-payments-row-minus {
    background-color: #ffccc7;
}

.student-payments-row-credit-paid {
    background-color: #c0c0c0;
}

.student-payments-row-credit {
    background-color: #ffca8e;
}

.calendar-card {
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08);
}

.ant-picker-calendar-header {
    padding-right: 10px;
}

.clickable-row td {
    vertical-align: top;
}

.ant-layout-sider {
    background: #1e1e24;
}

.ant-menu-dark {
    background: #1e1e24 !important;
}

.ant-menu-item {
    border-left: 4px solid transparent;
    height: unset !important;
    display: flex;
    align-items: center;
}

.ant-menu-item-selected {
    background: #282a31 !important;
    border-left: 4px solid #0094c4;
}

.ant-menu-submenu-title {
    display: flex;
    align-items: center;
    padding-left: 27px !important;

    svg {
        margin-right: 10px;
    }
}

.ant-statistic-content,
.ant-statistic {
    display: inline-block;
}

.payment-help__list li {
    margin-bottom: 20px;
}

.cp-container {
    position: relative;
    margin: 10px 0;
}

.cp-line {
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #F0F2F5;
    top: 50%;
}

.cp-check {
    position: absolute;
    top: -2px;
    right: -2px;
}

.cp-bubble {
    position: relative;
}

.warning .ant-card-body {
    padding: 10px 10px 10px 20px;
}

.next-lesson .ant-card-body {
    padding: 13px;
    background-color: #F0F2F5;
}

.next-lesson p {
    margin-bottom: 0;
}

.selected-row {
    background-color: rgb(144, 238, 144);
}

.change-lesson-group .ant-table-tbody>tr.ant-table-row:hover>td {
    background: unset;
}

.add-value-container .ant-btn {
    border-radius: 16px;
    padding: 5px 14px;
    font-weight: bold;
    margin-right: 4px;
}

.add-value-container .ant-btn:hover {
    background-color: #079DC1;
    color: #FFFFFF;
    border-color: unset;
}

.money-input input {
    border-right: unset;
}

.money-input {
    width: 150px;
    margin-bottom: 15px;
}

.money-input * {
    font-weight: bold;
}

.money-input .ant-input-group-addon {
    background-color: unset;
}

.payment-radio {
    display: flex;
    flex-direction: column;
}

.payment-radio .ant-radio-button-wrapper {
    padding: 15px 20px;
    height: unset;
    margin: 2px 0;
    background-color: #F0F2F5;
    border: 0;
    font-weight: bold;
}

.payment-radio .ant-radio-button-wrapper::before {
    background-color: unset;
}

.payment-radio .ant-radio-button-wrapper-checked {
    border: 2px solid #079DC1;
}

.help-collapse {
    border: 0;
    background-color: unset;
}

.help-collapse .ant-collapse-item {
    margin: 3px 0;
    border: 0;
    background-color: #F0F2F5;
}

.help-collapse .ant-collapse-header {
    font-weight: bold;
}

.help-collapse .ant-collapse-content-active {
    border: 0;
    background-color: #F0F2F5;
}

.deposit-info-col {
    padding: 24px 24px 0 0;
}

.custom-btn {
    padding: 10px 20px;
    height: unset;
    font-weight: bold;
    background-color: #079DC1;
}

.btn-green {
    background-color: #3AC700;
    border: unset;
}

.btn-green:hover {
    background-color: #37ad14;
    border: unset;
    color: #000;
}

.btn-blue {
    border: 0;
    border-radius: 2px;
    background-color: #079DC1;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.01);
    padding: 10px 20px;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;

    &:hover {
        background-color: darken($color: #059DC1, $amount: 2);
    }
}

.exam-card {
    background-color: unset;
    border-color: #3AC700;
}

.bank-ic {
    height: 20px;
    width: auto;
    position: relative;
    margin-left: 14px;
}

.bank-ic img {
    height: 100%;
    position: relative;
    top: -6px;
}

.bank-btn {
    width: 100%;
    padding: 20px;
}

.bank-btn {
    width: 100%;
    padding: 20px;
    height: 80px;
}

.balance-card {
    background-color: #23272D;
    color: #FFFFFF;
    border: 0;
    border-left: 4px solid #667380;

    .icon {
        margin-right: 10px;
    }

    .ant-statistic {

        .ant-statistic-title {
            color: #A1AAB3;
        }

        .ant-statistic-content {
            color: #FFFFFF;

            &-suffix a {
                display: inline-flex;
                align-items: center;
            }
        }
    }

    .add-balance-btn {
        margin-left: 5px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #079DC1;
        padding: 0;

        .anticon-plus {
            color: #FFFFFF;
            position: relative;
            top: -1px;
        }
    }
}

.notification-buttons button {
    margin-left: 10px;
}

.instructions-player-container {
    border: 1px solid #E0E6EC;
    border-radius: 2px;
    padding: 10px;
    display: inline-block;
    width: 100%;
}

.instructions-container {
    display: flex;
    flex-direction: column;
    justify-items: space-between;
    height: 100%;
    padding-top: 30px;
}

.read-more {
    display: flex;
    margin: auto 0 0;
}

.ic-bookmark-round {
    border-radius: 50%;
}

.bookmark-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: scroll;

    &.full-screen {
        position: fixed;
        z-index: 1000;
    }

    .bookmark-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(61, 75, 102, 0.1);
        padding: 48px;

        @media screen and (max-width: 767px) {
            width: 90%;
            // height: 90%;
            top: 5%;
            left: 5%;
            transform: unset;
        }

        .close-btn {
            position: absolute;
            top: 24px;
            right: 24px;
            width: 32px;
            height: 32px;
            background-color: #F0F2F5;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: darken($color: #F0F2F5, $amount: 2);
            }
        }

        .bookmark-icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #059DC1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }

        .centered {
            text-align: center;
        }

        h2 {
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            color: #252529;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            color: #676B73;
            margin-bottom: 30px;

            &.black {
                margin-bottom: unset;
                color: #252529;
            }
        }

        .time-container {
            padding: 10px 30px;
            border: 1px solid #D4DADE;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: #181A1F;
        }

        .add-bookmark {
            width: 100%;
            padding: 12px 20px;
            background-color: #059DC1;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            border: unset;
            border-radius: 2px;
            cursor: pointer;

            &:hover {
                background-color: darken($color: #059DC1, $amount: 2);
            }
        }

        .delete-bookmark {
            width: 100%;
            padding: 12px 20px;
            background-color: #ffffff;
            color: #CE3627;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            border: 1px solid #CE3627;
            border-radius: 2px;
            cursor: pointer;
            margin-top: 10px;

            &:hover {
                box-shadow: 0 0 11px rgba(33, 33, 33, .2);
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .ant-picker-panels {
        flex-direction: column;
    }

    .mobile-range-picker-btn {
        margin-left: 15px;
    }
}

@media screen and (max-width: 576px) {

    .mobile-range-picker {
        width: 100%;
    }

    .mobile-range-picker-btn {
        margin: 10px 0;
        width: 100%;
    }
}

.attachments {
    margin-top: 24px;
    padding-bottom: 24px;

    p {
        font-size: 15px;
        line-height: 18px;
        color: #686C74;
        padding-bottom: 10px;
        border-bottom: 1px solid #F0F2F5;
    }

    .file-card {
        padding: 13px 20px;
        background-color: #F0F2F5;
        display: inline-flex;
        margin-right: 15px;

        .icon-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .file-info-wrap {
            padding-left: 14px;

            span {
                font-size: 15px;
                line-height: 18px;
                color: #252529;
                margin-bottom: 5px;
                cursor: pointer;
            }
        }
    }
}

.chapter-navigation {
    cursor: pointer;
    // padding: 16px;

    &:hover {
        background-color: #f7f7f7;
    }

    &.disabled {
        opacity: .6;
        cursor: unset;

        &:hover {
            background-color: unset;
        }
    }

    .title-wrap {
        display: flex;
        margin-bottom: 12px;
        position: relative;

        b {
            font-size: 17px;
            line-height: 20px;
            color: #252529;
            display: inline-block;
            margin-left: 30px;
        }
    }


    &.right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
            text-align: right;
        }

        .title-wrap {

            b {
                margin-right: 30px;
                margin-left: 0;
            }
        }
    }
}

.ask-lecturer-modal {

    .ant-modal-footer {
        border-top: unset;
    }
}

.custom-header {
    background-color: #FFFFFF;
    padding: 12px 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08), 0 1px 0 0 #E2E7EA;

    @media screen and (max-width: 991px) {

        .go-back-btn {
            margin-left: 30px;
        }
    }

    @media screen and (max-width: 768px) {
        margin: -8px -8px 24px;
    }
}

.img-holder {
    margin-bottom: 21px;

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 4px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ant-btn-primary {
    background-color: #079DC1;
    background: #079DC1;
}

.shadow-card {
    border-radius: 2px;
    background: #FFF;
    box-shadow: 0px 3px 6px 0px rgba(64, 96, 128, 0.10);
}

.test-flags {
    margin-left: 11px;
    white-space: nowrap;
    position: relative;

    button {
        padding: 0;
        background-color: unset;
        border: unset;
        width: 20px;
        height: 20px;
        margin-left: 5px;

        &:hover {
            cursor: pointer;
        }

        img {
            // margin: 0 2px;
            width: 20px;
            height: 15px;
            border-radius: 2px;

            &.active {
                -webkit-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                -moz-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);

            }
        }
    }
}

.btn-start-csn-test {
    border: 0;
    border-radius: 4px;
    background-color: #079DC1;
    color: #FFFFFF;
    padding: 12px 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    height: auto;
}

.btn-review-csn-mistakes {
    border-radius: 4px;
    border: 1px solid #D8DCE6;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(64, 85, 128, 0.08);
    padding: 12px 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    height: auto;
}

.sub-modal-list {
    list-style: none;
    padding: 0;
    margin-bottom: 16px;

    li {
        display: flex;
        color: #4C5567;
        margin-bottom: 4px;
    }
}

.ant-modal-content {
    border-radius: 10px;
}

.btn-ghost-tests-filter {
    height: auto;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #676B73;
    border-radius: 2px;
    border: 1px solid #D8DCE6;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(64, 85, 128, 0.08);

    &.active {
        border-radius: 2px;
        border: 1px solid #079DC1;
        background: #E6FAFE;
        box-shadow: 0px 1px 2px 0px rgba(64, 85, 128, 0.08);
        color: #05748F;
    }
}
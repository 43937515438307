.module-exam-card {

    h2 {
        font-size: 24px;
        line-height: 24px;
        color: #252529;
        font-weight: bolder;
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: #676B73;
        margin-bottom: 24px;
    }

    .exam-status-container {
        padding: 48px;
        background-color: #F0F2F5;
        height: 70vh;
        position: relative;

        @media screen and (max-width: 768px) {
            height: unset;
        }
    }

    .status-wrap {
        width: 15%;
        margin-right: 24px;
        margin-bottom: 10px;

        @media screen and (max-width: 1455px) {
            width: 18%;
            margin-right: 5px;
        }

        @media screen and (max-width: 1290px) {
            width: 25%;
        }

        @media screen and (max-width: 1200px) {
            width: 32%;
        }

        @media screen and (max-width: 700px) {
            width: 40%;
        }

        @media screen and (max-width: 580px) {
            width: 50%;
        }


        p {
            line-height: 16px;
            color: #676B73;
            margin-bottom: 6px;
        }

        b {
            line-height: 19px;

            &.not-completed {
                color: #E35526;
            }

            &.completed {
                color: #2C9C3B;
            }
        }
    }

    .btn-start-test {
        border: 0;
        background-color: #079DC1;
        color: #FFFFFF;
        padding: 32px 84px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 768px) {
            position: static;
            transform: unset;
            top: unset;
            left: unset;
            width: 100%;
            margin-top: 20px;
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
        }

        &.disabled {
            background-color: #CCCCCC;

            &:hover {
                cursor: unset;
                box-shadow: unset;
            }
        }
    }
}

.module-exam-question-card {
    margin: 24px;
    min-height: 100vh;
    background-color: #FFFFFF;
    padding: 0 48px;

    @media screen and (max-width: 768px) {
        height: unset;
        padding: 0 20px;
        margin: 24px 0;
    }

    .time-is-up {
        padding: 80px;

        .icon-round-wrap {
            width: 64px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #EDEFF2;
            border-radius: 50%;
        }

        p {
            margin-bottom: 20px;
        }

        h3 {
            font-weight: bolder;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            color: #252529;

        }

        .btn-submit {
            // width: 100%;
            height: unset;
            padding: 16px 20px;
            margin-top: 20px;
            background-color: #059DC1;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            border: unset;
            border-radius: 2px;
            cursor: pointer;

            &:hover {
                background-color: darken($color: #059DC1, $amount: 2);
            }

            &.disabled {
                background-color: #ABAEB4;
                color: #FFFFFF;
                cursor: not-allowed;

                &:hover {
                    background-color: #ABAEB4;
                }
            }
        }

    }
}

.question-bubble {
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 1px solid #E0E6EC;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #181A1F;
    font-weight: bolder;
    margin-right: 30px;

    .ic-check {
        display: none;

    }

    .arrow-up {
        display: none;
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #079DC1;
    }

    &.correct {
        .ic-check {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &.incorrect {
        border: 2px solid #CC3326;
    }

    &.selected {
        .arrow-up {
            display: block;
        }
    }
}

.exam-end-card {
    padding: 112px 0;

    &>* {
        text-align: center;
    }

    h1 {
        font-size: 36px;
        line-height: 48px;
        font-weight: bolder;
        color: #252529;
        margin-bottom: 20px;
    }

    p {
        font-size: 15px;
        line-height: 18px;
        color: #676B73;
        margin-bottom: 10px;
    }

    .big-text {
        font-size: 24px;
        line-height: 34px;
        font-weight: bolder;
        margin-bottom: 10px;
    }

    .green-text {
        color: #2C9C3B;
    }

    .orange-text {
        color: #CC3326;
    }

    .questions-count-wrap {
        padding: 26px 30px 18px;
        background-color: #F0F2F5;
        height: 100%;
        width: 100%;

        p {
            font-size: 17px;
            line-height: 20px;
        }

        b {
            font-size: 36px;
            line-height: 48px;
            color: #252529;
            font-weight: bold;
        }

    }

    .review-link {
        border: 0;
        background-color: unset;
        font-size: 17px;
        line-height: 20px;
        color: #CC3326;
        cursor: pointer;
        margin: 10px auto 28px;
    }

    .btn-submit {
        // width: 100%;
        height: unset;
        padding: 16px 20px;
        margin-top: 20px;
        background-color: #059DC1;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        border: unset;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
            background-color: darken($color: #059DC1, $amount: 2);
        }

        &.disabled {
            background-color: #ABAEB4;
            color: #FFFFFF;
            cursor: not-allowed;

            &:hover {
                background-color: #ABAEB4;
            }
        }
    }
}
.instructor-card-container {
    width: 100%;
    height: 100%;
    position: relative;

    .instructor-card {
        box-shadow: 0 10px 20px 0 rgba(61, 82, 102, 0.2);
        height: 100%;
        border-radius: 2px;

        .ant-card-body {
            padding: 5px;
        }

        .top-container {
            position: relative;
            margin-bottom: 66px;
            min-height: 200px;

            .img-wrapper {
                height: 200px;
                overflow: hidden;
                background-color: #f0f0f0;

                img {
                    object-position: center center;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .instructor-photo-wrapper {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);
                z-index: 1;
                height: 100px;
                width: 100px;
                border-radius: 50%;
                border: 5px solid #FFFFFF;
                box-shadow: 0 2px 4px 0 rgba(61, 75, 102, 0.1);
                overflow: hidden;
                display: flex;
                justify-content: center;
                background-color: #a7a7a7;

                img {
                    object-position: center center;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .card-container {
            padding: 0px 19px 19px;
        }

        &:hover {
            height: unset;
            position: absolute;
            width: 100%;
            // transition: all .5s linear;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            box-shadow: 0 10px 20px 0 rgba(61, 82, 102, 0.2);

            .accept-button {
                display: block;
            }

            @media screen and (max-width: 991px) {
                position: relative;
            }
        }

        .accept-button {
            display: none;
            width: 100%;
            background-color: #079DC1;
            box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.01);
            border-radius: 2px;
            font-size: 14px;
            line-height: 22px;
            padding: 10px 20px;
            color: #FFFFFF;
            height: unset;
            margin-top: 5px;

            @media screen and (max-width: 991px) {
                display: block;
            }
        }

        h3 {
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            color: #252529;
            font-weight: 600;
            margin-bottom: 20px;

            .flags {
                margin-left: 11px;
                white-space: nowrap;

                img {
                    margin: 0 2px;
                    width: 20px;
                    height: 15px;
                    border-radius: 2px;
                }
            }
        }

        .param {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            color: #181A1F;
            display: block;
            margin-bottom: 3px;

            .ant-tag {
                font-weight: normal;
                margin-left: 10px;
            }
        }

        .param-title {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: #737A80;
            display: block;
            margin-bottom: 4px;
        }

        .param-row {
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 13px;

            .icon-wrap {
                width: 24px;
                height: 24px;
            }

            .param-col {
                margin-left: 12px;
            }
        }
    }

    &.inline-big {

        .instructor-card {
            box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08);

            &:hover {
                position: relative;
            }

            .ant-card-body {
                padding: 24px;
            }

            .main-col {
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                @media screen and (min-width: 992px) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .top-container {
                        order: 2;
                        margin-bottom: 0;
                        display: inline-flex;
                        flex-direction: row;

                        .img-wrapper {
                            min-height: unset;
                            max-height: unset;
                            width: 194px;
                            height: 194px;
                            margin: 0 4px;
                            border-radius: 4px;
                        }

                        .instructor-photo-wrapper {
                            all: unset;
                            width: 194px;
                            height: 194px;
                            overflow: hidden;
                            border-radius: 4px;
                            background-color: #f0f0f0;

                            img {
                                object-position: center center;
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .card-container {
                        order: 1;
                        display: inline-block;
                        padding: 5px 10px;

                        h3 {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    &.inline-small {

        .instructor-card {
            box-shadow: unset;

            .ant-card-body {
                padding: 20px;
            }

            .main-col {

                @media screen and (min-width: 992px) {
                    &.inline-small {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .top-container {
                        min-height: 60px;
                        height: 60px;
                        margin-bottom: 0;
                        display: flex;
                        flex-direction: row;

                        .img-wrapper {
                            min-height: unset;
                            max-height: unset;
                            height: 60px;
                            width: 60px;
                            margin: 0 4px;
                            border-radius: 4px;
                        }

                        .instructor-photo-wrapper {
                            all: unset;
                            height: 60px;
                            width: 60px;
                            overflow: hidden;
                            border-radius: 4px;
                            background-color: #f0f0f0;

                            img {
                                object-position: center center;
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .card-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 0;
                margin-left: 20px;
                width: 100%;



                .param-row {
                    margin: 0;
                    width: 25%;
                    align-items: flex-start;
                    padding-right: 10px;

                    @media screen and (max-width: 1200px) {
                        width: 50%;
                    }

                    @media screen and (max-width: 992px) {
                        width: 50%;
                    }

                    @media screen and (max-width: 768px) {
                        width: 50%;
                    }

                    @media screen and (max-width: 576px) {
                        width: 100%;
                    }

                    .param-col {
                        margin: 0;
                    }

                    .icon-wrap {
                        display: none;
                    }
                }

                h3 {
                    width: 100%;
                    text-align: left;
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 10px;
                }
            }

            &:hover {
                position: relative;
                box-shadow: 0 10px 20px 0 rgba(61, 82, 102, 0.2);
            }

            .accept-button {
                display: none;
            }
        }
    }

}
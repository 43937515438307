.e-learning-card {
    box-shadow: 0 3px 6px 0 rgba(64, 96, 128, 0.1);
    border-radius: 2px;
    margin-bottom: 48px;

    .ant-collapse-item {

        .ant-collapse-header {
            padding: 16px 24px 24px;
        }
    }
}

.e-learning-header {
    background-color: #FFFFFF;
    padding: 12px 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08), 0 1px 0 0 #E2E7EA;
    margin: -20px -20px 24px;

    @media screen and (max-width: 991px) {

        .go-back-btn {
            margin-left: 30px;
        }
    }

    @media screen and (max-width: 768px) {
        margin: -8px -8px 24px;
    }
}

.faq-collapse {

    .ant-collapse {

        .ant-collapse-header {
            padding: 16px 24px;
        }
    }
}

@media screen and (max-width: 576px) {

    .ask-lecturer-btn {
        width: 100%;
        margin-top: 10px;
    }
}
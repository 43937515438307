.lesson-player {
    display: inline-block;
    position: relative;
    width: 100%;
    // height: 1vh;
    // border-radius: 4px;
    // border: 1px solid black;
    background-color: #000000;

    .lesson-done-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F0F2F5;
        z-index: 5;

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                font-size: 20px;
                line-height: 26px;
                color: #252529;
                margin-bottom: 20px;
            }

            .check-icon {
                margin-bottom: 30px;
            }

            @media screen and (max-width: 576px) {
                position: relative;

                .check-icon {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }

            .link-next-chapter {
                display: flex;
                align-items: center;
                cursor: pointer;

                span {
                    display: block;
                    margin-right: 10px;
                    font-size: 17px;
                    line-height: 20px;
                    color: #252529;
                    font-weight: bolder;
                }
            }
        }
    }

    video {
        width: 100%;
        max-height: 80vh;
        object-position: center;

        &::-webkit-media-controls {
            display: none !important;
        }
    }

    &.fullscreen {

        video {
            max-height: unset;
            height: 100vh;
        }
    }

    video::-webkit-media-controls {
        display: none !important;
    }

    video::-webkit-media-controls-enclosure {
        display: none !important;
    }

    &.loading {
        width: 100%;
        // height: 80vh;
        border-radius: 4px;
        border: 1px solid black;
        background-color: #f3f3f3;
        display: none;

        video {
            display: none;
        }
    }

    .btn-play-big {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0;
        border-radius: 50%;
        transition: box-shadow .3s;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
        }

        @media screen and (max-width: 768px) {
            width: 60px;
            height: 60px;


        }
    }

    .pointer-info-wrap {
        display: none !important;
        position: absolute;
        top: -40px;
        left: 20px;
        background-color: #23272D;
        color: #FFFFFF;
        padding: 3px;
        border: 1px solid #FFFFFF;
        border-radius: 3px;

        &.show {
            display: block !important;
        }
    }

    .minus-quarter-sign {
        position: absolute;
        top: 50%;
        left: 4%;
        color: #FFF;
        font-size: 72px;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity linear .1s;

        &.show {
            opacity: .3;
        }
    }

    .player-controls {
        z-index: 2;
        // z-index: 2147483647;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        align-items: flex-end;

        .controls-bar {
            z-index: 2147483647;
            position: relative;
            bottom: 0;
            left: 0px;
            border: 1px solid #23272D;
            display: flex;
            width: 100%;
            background-color: #23272D;
            opacity: 1;
            transition: opacity linear .3s;

            * {
                display: block;
            }

            &.fullscreen {
                bottom: 0;
            }

            &.hide {
                opacity: 0;

                * {
                    display: none;
                }
            }

            .settings-menu {
                position: absolute;
                bottom: 38px;
                right: -38px;
                min-height: 60px;
                width: 100px;
                background-color: rgba(24, 26, 31, .7);
                padding: 12px;
                color: #FFFFFF;
                text-align: left;

                .setting-title {
                    font-size: 11px;
                    font-weight: 700;
                }

                .setting {
                    display: block;
                    font-size: 11px;
                    font-weight: 400;
                    cursor: pointer;

                    &:hover {
                        font-weight: 700;
                    }

                    &.active {
                        font-weight: 600;
                    }
                }
            }

            .player-btn {
                width: 38px;
                height: 38px;
                color: #FFF;
                background-color: transparent;
                border: 0;
                border-right: 1px solid #373E45;
                cursor: pointer;

                &.btn-play-small {
                    background: url('../img/ic-play.svg') center no-repeat;

                    &.pause {
                        background: url('../img/ic-pause.svg') center no-repeat;
                    }
                }

                &.btn-rewind-small {
                    background: url('../img/ic-rewind-15s.svg') center no-repeat;
                }

                &.btn-bookmark-small {
                    background: url('../img/ic-add-bookmark.svg') center no-repeat;

                }

                &.btn-volume-small {
                    background: url('../img/ic-sound.svg') center no-repeat;
                    position: relative;

                    &.mute {
                        background: url('../img/ic-sound-mute.svg') center no-repeat;
                    }

                    .volume-wrap {
                        display: flex;
                        justify-content: center;
                        padding: 15px 0 10px;
                        position: absolute;
                        top: -100px;
                        left: 0;
                        width: 38px;
                        height: 100px;
                        background-color: #181A1F;
                    }
                }

                &.btn-settings-small {
                    position: relative;
                    background: url('../img/ic-settings.svg') center no-repeat;
                }

                &.btn-fullScreen-small {
                    background: url('../img/ic-full-screen.svg') center no-repeat;
                }

                &:hover {
                    background-color: #181A1F;
                }

                &.disabled {
                    background-color: #404040;
                    cursor: not-allowed;
                }
            }

            .timeline {
                position: relative;
                flex-grow: 1;
                padding: 0 20px;
                display: flex;
                align-items: center;

                .bar {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    height: 4px;
                    background-color: #373E45;

                    .progress-bar {
                        height: 100%;
                        width: 0;
                        background-color: #7C8C9C;
                    }

                    .active-bar {
                        position: absolute;
                        top: -3px;
                        left: 0px;
                        width: 100%;
                        height: 10px;
                        background-color: white;
                        opacity: .0;
                    }

                    .bookmark-pin {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        left: 300px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }



                .timer {
                    display: inline-block;
                    color: #FFFFFF;
                    white-space: nowrap;
                    margin-left: 20px;
                }
            }
        }
    }
}
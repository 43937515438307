.checkpoints-reel {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;

    // .divider {
    //     border-top: 1px solid #F0F2F5;
    //     position: absolute;
    //     left: 0;
    //     top: 50%;
    //     width: 100%;
    // }

    .checkpoint {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 24px;
        margin-bottom: 5px;
        background-color: #F0F2F5;

        // .divider {
    //     border-top: 1px solid #F0F2F5;
    //     position: absolute;
    //     left: 0;
    //     top: 50%;
    //     width: 100%;
    // }

        &.done {
            border: 1px solid #E0E6EC;
            background-color: #FFFFFF;
        }
    }
}
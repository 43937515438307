.question-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .question-card {
        position: absolute;
        top: 2%;
        left: 2%;
        width: 96%;
        min-height: 96%;
        z-index: 5;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(61, 75, 102, 0.1);
        padding: 40px;

        &.time-is-up {
            width: unset;
            max-width: 478px;
            min-height: unset;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .icon-round-wrap {
                width: 64px;
                height: 64px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EDEFF2;
                border-radius: 50%;
            }

            p {
                margin-bottom: 20px;
            }

            h3 {
                font-weight: bolder;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                color: #252529;

            }

        }

        .close-btn {
            position: absolute;
            top: 24px;
            right: 24px;
            width: 32px;
            height: 32px;
            background-color: #F0F2F5;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: darken($color: #F0F2F5, $amount: 2);
            }
        }

        .question-wrap {
            width: 100%;

            .img-wrap {
                display: flex;
                justify-content: center;
                width: 100%;
                border-radius: 5%;
                border: 1px solid #f0f0f0;
                padding: 10px;
                margin-bottom: 20px;

                img {
                    max-height: 350px;
                    max-width: 100%;
                }
            }

            // .description-wrap {


            // }
        }

        .answer-wrap {

            .answer {
                display: flex;
                background-color: #F0F2F5;
                flex-wrap: nowrap;
                width: 100%;
                padding: 17px 20px;
                margin-bottom: 4px;
                border-radius: 2px;

                &.selected {
                    background-color: #f7f7f7;
                    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
                }

                &.correct {
                    background-color: #FFFFFF;
                    border: 2px solid #2C9C3B;
                }

                &.incorrect {
                    background-color: #FFFFFF;
                    border: 2px solid #CC3326;
                }

                .answer-col {
                    width: 90%;
                    cursor: pointer;

                    .img-wrap {
                        display: flex;
                        justify-content: start;
                        // border: 1px solid black;
                        max-height: 150px;
                        margin: 0 0 10px 0;
                        padding: 3px;
                        // background-color: #FFFFFF;

                        img {
                            max-width: 100%;
                            max-height: 200px;
                        }
                    }

                    p {
                        color: #000000;
                        font-weight: bold;
                        font-size: 14px;
                        margin: 0;
                    }
                }

            }
        }

        .warning {
            display: block;
            margin: 16px 0;
            font-size: 20px;
            line-height: 26px;

            &.red {
                color: #CC3326;
            }

            &.green {
                color: #2C9C3B
            }
        }

        .btn-submit {
            width: 100%;
            height: unset;
            padding: 16px 20px;
            margin-top: 20px;
            background-color: #059DC1;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            border: unset;
            border-radius: 2px;
            cursor: pointer;

            &:hover {
                background-color: darken($color: #059DC1, $amount: 2);
            }

            &.disabled {
                background-color: #ABAEB4;
                color: #FFFFFF;
                cursor: not-allowed;

                &:hover {
                    background-color: #ABAEB4;
                }
            }
        }
    }
}

.exam-question-card {
    height: 80vh;

    @media screen and (max-width: 768px) {
        height: unset;
    }

    .question-big-text {
        font-size: 24px;
        line-height: 34px;
        color: #252529;
        // font-weight: bolder;
        margin-bottom: 30px;
        display: block;
        width: 100%;
    }

    .img-wrap {
        width: 100%;
        margin-bottom: 10px;

        // margin: 0 0 20px 16px;
        &.question-img {
            height: 300px;

            // overflow: hidden;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }

    .description-wrap {
        padding: 10px;
        border: 1px solid #cccccc;
    }

    .answer {
        display: flex;
        background-color: #F0F2F5;
        flex-wrap: wrap;
        width: 100%;
        padding: 17px 20px;
        margin-bottom: 4px;
        border-radius: 2px;

        &.selected {
            background-color: #f7f7f7;
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
        }

        &.correct {
            background-color: #FFFFFF;
            border: 2px solid #2C9C3B;
        }

        &.incorrect {
            background-color: #FFFFFF;
            border: 2px solid #CC3326;
        }

        .answer-col {
            width: 90%;

            .img-wrap {
                // display: flex;
                // justify-content: center;
                // border: 1px solid black;
                height: 200px;
                width: 100%;
                margin: 0 0 10px 0;
                padding: 3px;
                overflow: hidden;
                // background-color: #FFFFFF;

                img {
                    // width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            p {
                color: #000000;
                font-weight: bold;
                font-size: 14px;
                margin: 0;
            }
        }

    }

    .warning {
        display: block;
        margin: 16px 0;
        font-size: 20px;
        line-height: 26px;

        &.red {
            color: #CC3326;
        }

        &.green {
            color: #2C9C3B
        }
    }

    .btn-submit {
        width: 100%;
        height: unset;
        padding: 16px 20px;
        margin-top: 20px;
        background-color: #059DC1;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        border: unset;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
            background-color: darken($color: #059DC1, $amount: 2);
        }

        &.disabled {
            background-color: #ABAEB4;
            color: #FFFFFF;
            cursor: not-allowed;

            &:hover {
                background-color: #ABAEB4;
            }
        }
    }
}